<template>
  <div>
    <SignIn />
  </div>
</template>

<script>
import SignIn from "@/components/SignIn";
export default {
  components: {
    SignIn,
  },
};
</script>

<style></style>
