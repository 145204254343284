<template>
  <div>
    <div class="default-layout container-fluid u-padding-0">
      <router-view />
    </div>
  </div>
</template>

<script>
import auth from "../mixins/auth";
export default {
  mixins: [auth],
};
</script>

<style></style>
