<template>
  <div
    :class="`o-alert--${alertBox.variant}`"
    v-if="alertBox.status"
    class="o-alert"
  >
    <slot v-if="hasSlot"></slot>
    <div v-else>{{ $t(alertBox.text) }}</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: () => ({
        status: false,
        text: "",
        variant: "",
      }),
    },
    duration: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      alertBox: {
        status: false,
        text: "",
        variant: "",
      },
      scopedSlots: [],
      hasSlot: false,
    };
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.SET_ALERT_BOX();
      },
    },
  },
  methods: {
    SET_ALERT_BOX() {
      this.CHECK_SLOTS();
      this.alertBox = { ...this.value };
      if (this.duration > 0)
        setTimeout(() => {
          this.CLEAR_ALERT_BOX();
        }, this.duration);
    },
    CLEAR_ALERT_BOX() {
      this.alertBox = {
        status: false,
        text: "",
        variant: "",
      };
    },
    CHECK_SLOTS() {
      this.scopedSlots = Object.keys(this.$scopedSlots);
      if (!this.scopedSlots.includes("default") && this.scopedSlots.length > 0)
        console.error("Slot isimi default olmak zorundadır.");
      if (this.scopedSlots.includes("default")) this.hasSlot = true;
    },
  },
  mounted() {
    this.SET_ALERT_BOX();
  },
};
</script>

<style></style>
