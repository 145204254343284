<template>
  <div v-if="accounts.length === 0" class="s-get-started__sign-up">
    <div
      class="s-get-started__sign-up__form-side u-display-flex u-flex-direction-column u-justify-content-center u-align-items-center"
    >
      <Logo />
      <h3 class="u-text-align-center">{{ $t("signIn.title") }}</h3>
      <p class="u-text-align-center">{{ $t("signIn.description") }}</p>
      <form @submit="CHECK_FORM">
        <Alert :value="alertBox">
          <i class="icofont-warning"></i> {{ alertBox.message }}
        </Alert>
        <BaseInput
          block
          type="email"
          inputId="email"
          v-model="form.email"
          :label="$t('form.email')"
          :placeholder="$t('form.enterEmail')"
        />
        <Button
          type="submit"
          variant="primary"
          block
          :loading="isLoading"
          class="u-margin-top-large"
        >
          {{ $t("form.continue") }}
          <i class="icofont-arrow-right"></i>
        </Button>
        <hr />
        <p v-html="$t('signInMessage')"></p>
      </form>
    </div>
    <div class="s-get-started__sign-up__right-side">
      <img
        class="u-page-open"
        v-show="active === 1"
        :src="`/images/side1.webp`"
        alt=""
      />
      <img
        class="u-page-open"
        v-show="active === 2"
        :src="`/images/side2.webp`"
        alt=""
      />
    </div>
  </div>
  <div v-else class="container-fluid">
    <div
      class="row u-justify-content-center u-align-items-center s-get-started__row"
      style="background-image: url('/bg-sign-in.svg')"
    >
      <div class="es-xs-12 es-md-6 es-lg-7 u-margin-top u-margin-bottom">
        <div class="o-card">
          <h4 class="u-color-primary">
            <strong> {{ $t("account.title") }} </strong>
          </h4>
          <p class="u-color-muted u-margin-bottom">
            {{ $t("account.message", { length: accounts.length }) }}
          </p>
          <div class="row u-justify-content-center u-align-items-center">
            <div
              class="es-xs-12 es-md-6 es-lg-4 u-text-align-center"
              v-for="(account, i) in accounts"
              :key="i"
            >
              <div class="s-get-started__item u-text-align-center">
                <div class="u-text-align-center">
                  <img
                    :src="api.image(account.logo)"
                    class="s-get-started__logo"
                    alt=""
                  />
                </div>
                <div class="u-margin-top">
                  <strong> {{ account.name }}</strong>
                </div>
                <div class="u-margin-top">
                  <a
                    target="_blank"
                    :href="
                      account.website.includes('https://')
                        ? account.website + '/panel?email=' + form.email
                        : 'https://' +
                          account.website +
                          '/panel?email=' +
                          form.email
                    "
                  >
                    <Button variant="primary">
                      {{ $t("continueToAccount") }}
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="u-margin-top-large u-text-align-center">
            <Button @click="CLEAR_ACCOUNTS()">
              <i class="icofont-refresh"></i> {{ $t("clearAccounts") }}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Logo";
import BaseInput from "@base/Inputs/BaseInput.vue";
import Button from "@base/Button.vue";
import GenerateSlug from "@utils/slug";
import { REQUEST_CHECK_EMAIL } from "@request/Companies";
import api from "@api";
import Alert from "../Base/Alert.vue";
export default {
  components: {
    Logo,
    BaseInput,
    Button,
    Alert,
  },
  data() {
    return {
      api,
      active: 1,
      activeMap: [1, 2],
      interval: -1,
      isLoading: false,
      form: {
        email: "",
      },
      alertBox: {
        status: false,
      },
      accounts: [],
    };
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  watch: {
    "$route.query": {
      deep: true,
      handler(data) {
        const item = data.email;
        if (!item) this.accounts = [];
        else {
          this.form.email = item;
          this.CHECK_FORM({ preventDefault: () => false });
        }
      },
    },
  },
  methods: {
    GenerateSlug,
    async CHECK_FORM(e) {
      e.preventDefault();
      this.CLEAR_ACCOUNTS();
      this.isLoading = true;
      const response = await REQUEST_CHECK_EMAIL({
        query: { email: this.form.email },
      });
      if (response.message === "OK") {
        this.accounts = response.data;

        if (response.data.length === 0) this.SHOW_NO_FOUND();
        else if (response.data.length === 1) {
          const item = response.data[0];
          if (item.panelWebsite.includes("https://"))
            location.href = item.panelWebsite + "?email=" + this.form.email;
          else
            location.href =
              "https://" + item.panelWebsite + "?email=" + this.form.email;
        }
        this.$router.push({ query: { email: this.form.email } });
      } else this.SHOW_NO_FOUND();
      this.isLoading = false;
    },
    SHOW_NO_FOUND() {
      this.alertBox = {
        status: true,
        message: this.$t("noFoundAccount"),
        variant: "warning",
      };
    },
    INTERVAL() {
      this.interval = setInterval(() => {
        const index = Math.ceil(Math.random() * 2);
        this.active = this.activeMap[index] || 1;
      }, 5000);
    },
    CLEAR_ACCOUNTS() {
      this.alertBox = { status: false };
      this.accounts = [];
    },
  },
  mounted() {
    const email = document.getElementById("email");
    if (email) email.focus();
    this.INTERVAL();
  },
};
</script>

<style></style>
