import crypto from "../utils/crypto";
import i18n from "@helpers/i18n.js";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getActiveUser", "getAppLoaded"]),
  },
  methods: {
    // Hata alındığında çalıştırıp kullanıcıyı bilgilendiren fonksiyon.
    SET_AUTH_MESSAGE() {
      // this.$store.dispatch("LOGOUT_USER", {
      //   message: this.$t(message),
      //   variant,
      // });
    },
    // uygulamaya set eden fonksiyon.
    // user bilgisi set edilir.
    // uygulama içi gerekli istekler atılır.
    SET_APP() {
      const user = JSON.parse(crypto.decrypto(localStorage.getItem("user"))); // user bilgisi alınmaya çalışılınır.
      if (user) {
        this.$store.commit("SET_ACTIVE_USER", user); // kullanıcı store'a set edilir.
      }
      // kullanıcının bilgilerine ulaşılırsa uygulamaya set et.
      else this.SET_AUTH_MESSAGE(i18n.t("authMessages.sessionIsEnd")); // user bilgisi alınamadıysa kullanıcıyı dışarıya çıkart.
    },
    // expired At süresini kontrol eden fonksiyon.
    SET_EXPIRED_AT() {
      const eat = Number(crypto.decrypto(localStorage.getItem("eat"))); // token expired at'i varsa onu getir.
      const today = new Date().getTime();
      return { eat, today };
    },
    // uygulamanın giriş fonksiyonudur. Kullanıcının bilgisi varsa ve token geçerlilik süresi devam ediyor ise uygulamaya kullanıcı giriş yapabilir.
    // diğer durumlarda kullanıcı login'e gönderilir.
    SET_INITIAL_APP() {
      this.$store.commit("SET_APP_LOADED", false);
      try {
        const { eat, today } = this.SET_EXPIRED_AT(); // expired At süresini kontrol eden fonksiyon.
        if (eat < today)
          this.SET_AUTH_MESSAGE(i18n.t("authMessages.sessionIsEnd"));
        // token süresi dolmuş ise kullanıcıyı dışarıya çıkart.
        else {
          this.SET_APP(); // uygulamaya set eden fonksiyon.
        }
      } catch (error) {
        this.SET_AUTH_MESSAGE(i18n.t("authMessages.somethingWentWrong")); // herhangi bir problemde kullanıcıyı dışarıya çıkart.
      }
    },
  },
  created() {
    this.SET_INITIAL_APP();
  },
};
